<script setup lang="ts">
import { showToast } from 'vant'
import Country from '~/net/models/country'
import { ConfigApi } from '~/net/apis/config_api'
import { CommonApi } from '~/net/apis/common_api'
import { PersonalApi } from '~/net/apis/personal_api'
import { getBrowserFinger } from '~/utils/finger'

const onClickLeft = () => history.back()
const userStore = useUser()
const show = ref(false)
const areaCode = ref<string>('+63')
const phone = ref('')
const code = ref('')

const countryList = ref<Country[]>([])
const minlength = ref(10)
const maxlength = ref(10)
const sMsCode = '获取验证码'
const codeName = ref(sMsCode)

const oldPhone = computed(() => {
  return userStore.userInfo.mobile || '1028187238'
})
phone.value = oldPhone.value
const isCode = computed(() => {
  return code.value.length !== 6
})
const isMatch = computed(() => {
  const len = phone.value.length
  return !(minlength.value ? len >= minlength.value && len <= maxlength.value : len === maxlength.value)
})
const isSubmintDisabled = computed(() => {
  return !code.value || !phone.value || isMatch.value || isCode.value
})

function onSelect(item: Country) {
  // 默认情况下点击选项时不会自动收起
  // 可以通过 close-on-click-action 属性开启自动收起
  show.value = false
  areaCode.value = item.code || '+63'
  maxlength.value = (item.len) ? item.len[item.len.length - 1] : 10
  minlength.value = item.len ? item.len[0] : 10
}

async function dict() {
  const { data } = await ConfigApi.dict({ key: 'jav.zonecode.cellphone.msg' })
  const json = JSON.parse(data[0].value)
  countryList.value = [...json].map((item) => {
    return new Country({ ...item })
  }).map((item) => {
    item.name = `${item.code}${item.cnName}`
    return item
  })
}

async function sendSmsCode() {
  if (codeName.value !== sMsCode)
    return
  if (!phone.value) {
    showToast('手机号码不允许为空')
    return
  }
  if (isMatch.value) {
    showToast('手机号码格式错误')
    return
  }
  const { data } = await CommonApi.sendCodeWithPhone({ userPhone: `${areaCode.value}-${phone.value}` })
  if (data === true) {
    showToast('发送成功')
    changeCode(30)
  }
}

function changeCode(num: number) {
  if (num < 0) {
    return codeName.value = sMsCode
  }
  codeName.value = `${num}s`
  setTimeout(() => {
    changeCode(num - 1)
  }, 1000)
}

function submintHandle() {
  if (!isSubmintDisabled) {
    return ''
  }
  phoneBinding()
}
async function phoneBinding() {
  const finger = await getBrowserFinger()
  await PersonalApi.phoneLogin({
    deviceNo: finger,
    v: code.value,
    phone: `${areaCode.value}-${phone.value}`
  })
  showToast('账号找回成功！')
}

dict()
</script>

<template>
  <div h-screen>
    <van-nav-bar title="手机号码找回" left-arrow @click-left="onClickLeft">
      <!-- <template #right>
        <van-icon name="service-o" size="20" @click="goService" />
      </template> -->
    </van-nav-bar>
    <div px-4 ct-E8E8E8>
      <template v-if="!oldPhone">
        <div mt-4>手机号码</div>
        <div flex flex-row items-center border-b-1 border-b-gray-800>
          <span flex flex-row items-center @click="show = true">
            {{ areaCode }}
            <van-icon name="arrow-down" ml-2 />
          </span>
          <van-field
            v-model="phone" label="" placeholder="请输入手机号码"
            :minlength="minlength"
            :maxlength="maxlength"
          />
        </div>
        <span v-show="phone.length && isMatch" class="error">手机号码格式错误</span>
      </template>
      <template v-else>
        <div mt-4>已绑定手机号</div>
        <div border-b-1 pb-2 pt-2>
          {{ oldPhone }}
        </div>
      </template>

      <div mt-4 flex items-center justify-between border-b-1 border-b-gray-800>
        <div>
          <p>手机验证码</p>
          <van-field
          v-model="code"
          minlength="6"
          maxlength="6"
          label="" ml--4 placeholder="请输入手机验证码" />
        </div>
        <van-button
        round plain color="#FD5B03"
        :disabled="codeName !== sMsCode" @click="sendSmsCode">
        {{ codeName }}
        </van-button>
      </div>
      <span v-show="code.length && isCode" class="error">验证码格式错误</span>
      <div mt-4 flex justify-center>
        <div round h-11 w-88 text-center ct-ffffff class="bg-bt" :class="{ disabled: isSubmintDisabled }" @click="submintHandle">提交</div>
      </div>
    </div>
    <van-action-sheet
      v-model:show="show"
      :actions="countryList"
      cancel-text="取消"
      close-on-click-action
      @cancel="show = false"
      @select="onSelect"
    />
  </div>
</template>

<style scoped>
.error{
  color: red;
  font-size: 11px;
  line-height: 0;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.block {
  width: 290px;
  height: 245px;
  background-color: #111425;
  color: #e8e8e8;
  border-radius: 10px;
  box-shadow: 0px 2px 3px 0px #9999990a;
}
:deep(.van-password-input__security) {
  height: 30px;
}
:deep(.van-nav-bar__content) {
  background: #111425;
}
:deep(.van-nav-bar__title) {
  color: #d0d0d0;
}
:deep(.van-nav-bar .van-icon) {
  color: #ffffff;
}
:deep(.van-action-sheet__item) {
  background: #242943;
  color: #e8e8e8;
}
:deep(.van-action-sheet__cancel) {
  background: #242943;
  color: #e8e8e8;
}
:deep(.van-action-sheet__gap) {
  background: #414660;
}
:deep(.van-action-sheet__item:hover) {
  color: #fd5f03;
}
:deep(.van-field__control) {
  color: #e8e8e8;
}
:deep(.van-field__control::placeholder) {
  color: #5c5e69;
}
:deep(.van-cell) {
  font-size: 16px;
  background: #111425;
  color: #e8e8e8;
  border-bottom: 1px solid #41466040;
}
:deep(.van-cell:after) {
  border: none;
}
:deep(.van-cell-group--inset) {
  border-radius: unset;
}
:deep(.van-field__label) {
  color: #e8e8e8;
}
:deep(.van-cell__value) {
  color: #5c5e69;
}
.bg-bt {
  background: linear-gradient(130.94deg, #fd9403 16.87%, #fd5b03 89.42%);
  line-height: 44px;
  border-radius: 10px;
  &.disabled{
    pointer-events: none;
    opacity: 0.6;
  }
}
:deep(.van-button) {
  background: transparent;
}
:deep(.van-password-input__security li) {
  background: #242943;
  width: 24px;
  height: 24px;
}
:deep(.van-password-input__security) {
  height: 24px;
}
:deep(.van-hairline--bottom:after) {
  border-bottom-width: 0;
}
:deep(.van-password-input__security i) {
  background: #e8e8e8;
}
</style>
